export const SEARCH_DEBOUNCE_TIME_MS = 800;
export const SEARCH_RESET_TIME_MS = 100;

export const SEARCH_NAVIGATIONAL_PAGES = (storeSlug: string) => [
  { path: '/dashboard', title: 'Dashboard' },
  { path: '/claims', title: 'Claims' },
  { path: '/store-credit', title: 'Store Credit' },
  { path: `/${storeSlug}/settings/general`, title: 'Settings' },
  { path: `/${storeSlug}/payouts`, title: 'Payouts' },
  { path: `/${storeSlug}/team-members`, title: 'Team Members' },
];
